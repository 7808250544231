import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { deleteCompany, getCompany } from '../../../ducks/company';
import { AppLoadContext } from "../../../components/ui/AppLoadContext";
import DataGrid from "../../../components/DataGrid";
import { ALERT_TYPE, COMPANY_STATUS_VALUE, SUBSCRIPTION, USER_ROLE } from '../../../constants';
import Loader from "../../../components/ui/Loader";
import withAuth from "../../../components/hoc/withAuth";
import { formatPhoneNumber, isUserSysAdmin, isUserClientAdmin, omitProps } from '../../../utils/utils';
import messages from "../../../utils/helper/messages";
import ActionBar from "../../../components/ui/ActionBar";
import ConfirmModal from "../../../components/ui/ConfirmModal";
import OverflowText from "../../../components/ui/OverflowTooltip";
import NewButton from "../../../components/ui/NewButton";
import AddCompanyModal from "./AddCompanyModal";
import GridButton from "../../../components/ui/GridButton";
import Icon from "../../../components/ui/Icon";
import { AlertContext } from "../../../context/AlertContext";
import SubscriptionModal from './SubscriptionModal';
import CompanyLogo from '../../../components/ui/CompanyLogo';


function Company() {
    const dispatch = useDispatch();

    const [confirmModal, setConfirmModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [subscriptionModal, setSubscriptionModal] = useState(false);
    const [info, setInfo] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [isDataUpdated, setIsDataUpdated] = useState(false);
    const [companyData, setCompanyData] = useState(null);
    const [logoCount, setLogoCount] = useState(0);

    const isAppLoaded = useContext(AppLoadContext);
    const { showAlert } = useContext(AlertContext);
    const companies = useSelector((state) => state.company.companies);
    const currentUser = useSelector((state) => state.user.currentUser);
    const companyLogos = useSelector((state) => state.company.companyLogos);

    const handleEdit = (data) => {
        setShowModal(true);
        setSelectedCompany(data);
    }

    const handleSubscriptions = (data) => {
        setSubscriptionModal(true);
        setSelectedCompany(data);
    }

    const handleAdd = () => {
        setSelectedCompany(null);
        setShowModal(true);
    }

    const handleAddModalClose = () => {
        setSelectedCompany(null);
        setShowModal(false);
    }

    const handleConfirmClose = () => {
        setConfirmModal(false);
        setInfo(null);
    }

    const handleConfirm = () => {
        handleDelete(info);
        handleConfirmClose();
    }

    const handleDeleteConfirm = (data) => {
        setInfo(data);
        setConfirmModal(true);
    }

    const handleDelete = (e) => {
        dispatch(deleteCompany({
            ...e,
            recordStatus: 'A'
        }))
        showAlert(messages.success.deleteCompany, ALERT_TYPE.SUCCESS);
    }

    const handleSubscriptionModalClose = () => {
        setSubscriptionModal(false);
        setSelectedCompany(null);
    }

    const rowActions = ({ row }) => {
        return (
            <div className="d-flex">
                {isUserClientAdmin(currentUser) &&
                    <GridButton
                        variant="light" size="sm" className="my-1" title="View"
                        onClick={() => handleEdit(row.original)}>
                        <Icon icon="view" size={14} />
                    </GridButton>}
                {isUserSysAdmin(currentUser) &&
                    <>
                        <GridButton
                            variant="light" size="sm" className="me-1 my-1" title="Edit"
                            disabled={row.original.recordStatus === COMPANY_STATUS_VALUE.Permanent}
                            onClick={() => handleEdit(row.original)}>
                            <Icon icon="edit" size={14} />
                        </GridButton>
                        <GridButton
                            variant="light" size="sm" className="me-1 my-1" title="Subscriptions"
                            disabled={row.original.recordStatus === COMPANY_STATUS_VALUE.Permanent}
                            onClick={() => handleSubscriptions(row.original)}>
                            <Icon icon="financial" size={14} />
                        </GridButton>
                        <GridButton
                            variant="light" size="sm" className="my-1" title="Delete"
                            disabled={row.original.recordStatus === COMPANY_STATUS_VALUE.Permanent}
                            onClick={() => handleDeleteConfirm(row.original)}>
                            <Icon icon="delete" size={14} />
                        </GridButton>
                    </>
                }
            </div>
        )
    }

    const columns = useMemo(
        () => [
            {
                id: 'logo',
                header: 'Logo',
                size: 160,
                cell: ({ row }) => <CompanyLogo row={row} />
            },
            {
                id: 'name',
                header: 'Company Name',
                accessorKey: 'name',
                cell: ({ row, cell }) => (
                    <OverflowText id={cell.id}>
                        {row.original.name}
                    </OverflowText>
                ),
                maxSize: 200,
                sortingFn: 'text',
            },
            {
                id: 'fullName',
                header: 'Contact Person',
                cell: ({ row, cell }) => {
                    let personName = `${row.original.contactFirstName} ${row.original.contactLastName}`;
                    return (
                        <OverflowText id={cell.id}>{personName}</OverflowText>
                    );
                },
                maxSize: 170,
                sortingFn: 'text',
            },
            {
                id: 'contactEmail',
                header: 'Email',
                accessorKey: 'contactEmail',
                cell: ({ row, cell }) => (
                    <OverflowText id={cell.id}>
                        {row.original.contactEmail}
                    </OverflowText>
                ),
                maxSize: 170,
                sortingFn: 'text',
            },
            {
                id: 'contactPhone',
                header: 'Phone',
                accessorKey: 'contactPhone',
                cell: ({ row }) => (
                    <div className="text-nowrap">
                        {formatPhoneNumber(row.original.contactPhone)}
                    </div>
                ),
                size: 120,
            },
            {
                id: 'address',
                header: 'Company Address',
                accessorKey: 'address',
                enableSorting: false,
                cell: ({ row, cell }) => (
                    <OverflowText id={cell.id}>
                        {row.original.address}
                    </OverflowText>
                ),
                maxSize: 200,
            },
            {
                id: 'city',
                header: 'City',
                accessorKey: 'city',
                sortingFn: 'text',
            },
            {
                id: 'state',
                header: 'State',
                accessorKey: 'state',
                size: 100,
                sortingFn: 'text',
            },
            {
                id: 'postalCode',
                header: 'Postal',
                accessorKey: 'postalCode',
                size: 110,
            },
            {
                name: 'actions',
                header: 'Actions',
                cell: rowActions,
                size: 90,
            },
        ],
        [companyData]
    );

    const memoizedCompanyData = useMemo(() => companyData, [companyData, isDataUpdated]);

    useEffect(() => {
        if (isAppLoaded && currentUser) {
            isUserSysAdmin(currentUser) ? dispatch(getCompany()) : dispatch(getCompany(currentUser?.companyId));
        }
    }, [dispatch, isAppLoaded, currentUser]);

    useEffect(() => {
        if (companies) {
            setCompanyData(companies);
            setIsDataUpdated(false);
        }
    }, [companies]);

    useEffect(() => {
        if (companyLogos) {
            const count = Object.keys(companyLogos).length;
            setLogoCount(count);
        }
    }, [companyLogos]);

    return (
        <>
            <ActionBar>
                {currentUser?.roleId === USER_ROLE.SYSTEM_ADMIN ? (
                    <NewButton
                        label="Add Company"
                        onClick={handleAdd}
                    />
                ) : null}
            </ActionBar>

            {companyData === null ? (
                <Loader />
            ) : (
                <DataGrid
                    columns={columns}
                    data={memoizedCompanyData}
                    enableFilters={false}
                    disablePagination={logoCount < Math.min(companies.length, 10)}
                />
            )}

            <AddCompanyModal
                show={showModal}
                onCancel={handleAddModalClose}
                onUpdate={() => setIsDataUpdated(true)}
                companyInfo={omitProps(selectedCompany, Object.values(SUBSCRIPTION))}
                viewOnly={isUserClientAdmin(currentUser)}
            />

            {subscriptionModal && (
                <SubscriptionModal
                    show={subscriptionModal}
                    data={selectedCompany}
                    onCancel={handleSubscriptionModalClose}
                />
            )}

            <ConfirmModal
                show={confirmModal}
                message={messages.confirm.deleteCompany}
                onConfirm={handleConfirm}
                onCancel={handleConfirmClose}
                onHideCallback={handleConfirmClose}
            />
        </>
    );
}

export default withAuth(Company);
