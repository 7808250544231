import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyLogos } from '../../ducks/company';
import Loader from './Loader';
import styles from './CompanyLogo.module.scss';
import api from '../../services/api/companyMessageService';

const CompanyLogo = ({ row }) => {
    const dispatch = useDispatch();
    const companyLogos = useSelector((state) => state.company.companyLogos);

    const [isLoading, setIsLoading] = useState(false);
    const [logoSrc, setLogoSrc] = useState('');

    const { id, logo } = row.original;

    const fetchCompanyLogo = () => {
        api.GetCompanyLogo(id)
            .then((response) => {
                const { data } = response;
                setLogoSrc(data.imageSrc);
                setIsLoading(false);
                dispatch(getCompanyLogos(id, data.imageSrc));
            })
            .catch((error) => {
                setLogoSrc('');
                setIsLoading(false);
                dispatch(getCompanyLogos(id, ''));
                console.error(error);
            });
    }

    useEffect(() => {
        if(id) {
            if (!companyLogos || [undefined, null].includes(companyLogos[id])) {
                setIsLoading(true);
                fetchCompanyLogo();
            } else {
                setLogoSrc(companyLogos[id]);
            }
        }
    }, []);

    return (
        <div className="position-relative">
        { isLoading ?
            <Loader size={1} />
            :
            <div className={styles.logoWrapper}>
                <img
                    src={logoSrc}
                    alt={logo}
                    height={40}
                    width="auto"
                />
            </div>
        }
        </div>
    )
}

export default CompanyLogo;
