import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import SharedForm from "../../../components/ui/Form";
import { AppLoadContext } from "../../../components/ui/AppLoadContext";
import {
    createCompany,
    getCompany,
    getCompanyById,
    getCompanyLogoById,
    resetCompany, resetLogos,
    updateCompany,
} from '../../../ducks/company';
import { isObjectEmpty, isUserSysAdmin, isValidName, omitProps, optionValues } from '../../../utils/utils';
import messages from "../../../utils/helper/messages";
import {
    ALERT_TYPE,
    ALIGN,
    POSITIONS,
    SUBSCRIPTION,
    US_STATE,
    VALIDATIONS,
} from '../../../constants';
import { AlertContext } from "../../../context/AlertContext";
import Loader from "../../../components/ui/Loader";

const AddCompanyModal = ({ show, onCancel, onUpdate, companyInfo, viewOnly }) => {
    const id = companyInfo?.id;

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [companyDetails, setCompanyDetails] = useState(null);
    const [isFileUpdated, setIsFileUpdated] = useState(false);

    const isAppLoaded = useContext(AppLoadContext);
    const { showAlert } = useContext(AlertContext);
    const { company } = useSelector((state) => state.company);
    const currentUser = useSelector((state) => state.user.currentUser);
    const stateOptions = optionValues(US_STATE);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isLogoLoading, setIsLogoLoading] = useState(false);

    const colorOptions = [
        { id: 'oxfordBlue', label: 'Oxford Blue', value: '#0c153d' },
        { id: 'greyLight', label: 'Grey Light', value: '#f7f7f7' }
    ];

    const arr = [
        { key: 'name', labelName: 'Company Name', placeholderName: 'Company Name', type: 'text' },
        { key: 'address', labelName: 'Address', placeholderName: 'Address', type: 'text' },
        { key: 'city', labelName: 'City', placeholderName: 'City', type: 'text' },
        { key: 'state', labelName: 'State', placeholderName: 'State', type: 'options', optionValues: stateOptions, minLength: 2, maxLength: 2 },
        { key: 'postalCode', labelName: 'Postal Code', placeholderName: 'Postal Code', type: 'text', minLength: 5, maxLength: 9, valid: VALIDATIONS.Number.isZipCode },
        { key: 'contactFirstName', labelName: 'Contact First Name', placeholderName: 'Contact First Name', type: 'text' },
        { key: 'contactLastName', labelName: 'Contact Last Name', placeholderName: 'Contact Last Name', type: 'text' },
        { key: 'contactEmail', labelName: 'Contact Email', placeholderName: 'Contact Email', type: 'email' },
        { key: 'contactPhone', labelName: 'Contact Phone', placeholderName: 'Contact Phone', type: 'tel', maxLength: 10 },
        { key: 'logo', labelName: 'Logo', placeholderName: 'Logo', type: 'file' },
        { key: 'logoBgColor', labelName: 'Logo Background', placeholderName: 'Select Logo Background', type: 'radio', optionValues: colorOptions }
    ];

    const onHideCallback = () => {
        dispatch(resetCompany());
        setCompanyDetails(null);
        onCancel();
    }

    const handleCompanyChange = (update) => {
        setCompanyDetails((details) => ({ ...details, ...update }))
    }

    const inputChange = (name, value) => {
        handleCompanyChange({ [name]: value });
        if (name === 'logo') {
            setIsFileUpdated(true);
        }
    }

    const handleSuccess = () => {
        setIsLoading(false);
        showAlert(id ? messages.success.updateCompany : messages.success.createCompany, ALERT_TYPE.SUCCESS);

        if (isUserSysAdmin(currentUser)) {
            dispatch(getCompany());
            if (isFileUpdated && id) {
                dispatch(resetLogos(id));
            }
        } else {
            dispatch(getCompany(currentUser?.companyId))
            dispatch(resetLogos(id));
        }
        setIsFileUpdated(false);
        onUpdate();
        onHideCallback();
    }

    const Submit = async () => {
        setIsLoading(true);
        try {
            if (id) {
                dispatch(updateCompany(companyDetails, handleSuccess));
            } else {
                dispatch(createCompany(companyDetails, handleSuccess));
            }
        }
        catch (error) {
            setIsLoading(false);
            showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
        }
    }

    const isValidCompanyInfo = () => {
        if (isObjectEmpty(companyDetails)) {
            return false;
        }

        return isValidName(companyDetails.name) && companyDetails.logo
    }

    const handleGetCompanyIdSuccess = () => {
        setIsDataLoading(false);
    };

    const handleError = (error) => {
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    };

    const handleGetCompanyLogoSuccess = () => {
        setIsLogoLoading(false);
    }

    useEffect(() => {
        if (isAppLoaded) {
            if (id) {
                setIsDataLoading(true);
                dispatch(getCompanyById(id, handleGetCompanyIdSuccess, handleError));
                setIsLogoLoading(true);
                dispatch(getCompanyLogoById(id, handleGetCompanyLogoSuccess, handleError));
            }
            else {
                dispatch(resetCompany());
            }
        }
        // Safe to add dispatch to the dependencies array
    }, [isAppLoaded, dispatch, id]);

    useEffect(() => {
        if (company) {
            const details = omitProps(company, Object.values(SUBSCRIPTION));
            handleCompanyChange({ ...details });
        }
    }, [company]);

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onHideCallback}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title> {viewOnly === true ? 'View' : (id ? 'Edit' : 'New')} Company</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2">
                    {(isDataLoading || isLogoLoading) &&
                        <Loader />
                    }
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={company}
                        onSubmit={Submit}
                        onCancel={onHideCallback}
                        onInputChanged={inputChange}
                        loading={isLoading}
                        disabled={!isValidCompanyInfo()}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        submitButtonText={id ? 'Update' : 'Submit'}
                        viewOnly={viewOnly}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AddCompanyModal;
