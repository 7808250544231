import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Spinner } from "react-bootstrap";
import withAuth from "../../../components/hoc/withAuth";
import Loader from "../../../components/ui/Loader";
import DataGrid from "../../../components/DataGrid";
import { getAdvisoryGroupRepresentativeneData } from "../../../ducks/advisoryGroup";
import { AppLoadContext } from "../../../components/ui/AppLoadContext";
import OverflowText from "../../../components/ui/OverflowTooltip";
import GridButton from "../../../components/ui/GridButton";
import Icon from "../../../components/ui/Icon";
import GridCellRating from "../../../components/ui/GridCellRating";
import { AG_REPSCORE_TYPE, PROGRAM_TYPE, REVISED_SCORE_TYPE } from '../../../constants';
import RepresentativenessDetailsModal from "./RepresentativenessDetailsModal";
import api from '../../../services/api/advisoryGroupService';

const Representativeness = () => {
    const dispatch = useDispatch();

    const isAppLoaded = useContext(AppLoadContext);
    const repsData = useSelector((state) => state.advisoryGroup.representativeList);
    const currentUser = useSelector((state) => state.user.currentUser);

    const [detailsModal, setDetailsModal] = useState(false);
    const [scoreType, setScoreType] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [repScoreData, setRepScoreData] = useState(null);

    const RowActions = ({ row }) => (
        <>
            <GridButton variant="light" size="sm" className="me-1 my-1" title="View"
                onClick={() => handleDetailsModalOpen(row.original.id, AG_REPSCORE_TYPE.Baseline)}>
                <Icon icon="view" size={14} />
            </GridButton>
            <GridButton variant="light" size="sm" className="me-1 my-1" title="Selection"
                onClick={() => handleDetailsModalOpen(row.original.id, AG_REPSCORE_TYPE.Panel)}>
                <Icon icon="panel-selection_1" size={21} />
            </GridButton>
            <GridButton variant="light" size="sm" className="me-1 my-1" title="Participation"
                disabled={row.original.participationScore === null}
                onClick={() => handleDetailsModalOpen(row.original.id, AG_REPSCORE_TYPE.Attendance)}>
                <Icon icon="participation" size={15} />
            </GridButton>

            {row.original.loading && row.original.loading === true ? (
                <Spinner
                    animation="border"
                    size="sm"
                    className="ms-2" />
            ) : <GridButton variant="light" size="sm" className="me-1 my-1" title="Update Participation Score"
                onClick={() => updaterepScore(row.original.id)}>
                <Icon icon="update-score" size={15} />
            </GridButton>}
        </>
    );

    const updaterepScore = (agId) => {
        let repScoreDataCopy = [...repScoreData];
        const repScoreDataIndex = repScoreDataCopy.findIndex(element => element.id === agId);
        repScoreDataCopy[repScoreDataIndex].loading = true;
        setRepScoreData([...repScoreDataCopy],
            api.GetRevisedRepScore(agId, { tempSelectedMember: [] }, REVISED_SCORE_TYPE.AttendanceScore).then(
                (res) => {
                    const score = res?.data?.repScores?.find(score => score.type === REVISED_SCORE_TYPE.AttendanceScore);
                    repScoreDataCopy[repScoreDataIndex].participationScore = score?.aggregateScore ? score?.aggregateScore : null;
                    repScoreDataCopy[repScoreDataIndex].loading = false;
                    setRepScoreData(repScoreDataCopy);
                }
            ));
    }

    const columns = [
        {
            id: 'shortName',
            header: 'AG Name',
            accessorKey: 'shortName',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.shortName}
                </OverflowText>
            ),
            maxSize: 300,
            sortingFn: 'text',
        },
        {
            id: 'type',
            header: 'AG Type',
            accessorKey: 'type',
            accessorFn: (row) => PROGRAM_TYPE[row.type],
            enableSorting: false,
        },
        {
            id: 'market',
            header: 'Market',
            accessorKey: 'market',
            enableSorting: false,
        },
        {
            id: 'selectionScore',
            header: 'Selection Score',
            accessorKey: 'selectionScore',
            cell: ({ row }) => <GridCellRating rating={row.original.selectionScore} customClass="my-1" />,
            enableSorting: false,
        },
        {
            id: 'participationScore',
            header: 'Participation Score',
            accessorKey: 'participationScore',
            cell: ({ row }) => <GridCellRating rating={row.original.participationScore} customClass="my-1" />,
            enableSorting: false,
        },
        {
            name: 'actions',
            header: 'Actions',
            size: 90,
            enableColumnFilter: false,
            cell: RowActions,
        },
    ];

    const handleDetailsModalOpen = (id, type) => {
        setSelectedId(id);
        setScoreType(type);
        setDetailsModal(true);
    }

    const handleDetailsModalClose = () => {
        setSelectedId(null);
        setScoreType(null);
        setDetailsModal(false);
    }

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getAdvisoryGroupRepresentativeneData(currentUser?.companyId));
        }
    }, [isAppLoaded, currentUser]);

    useEffect(() => {
        if (repsData) {
            setRepScoreData(repsData)
        }
    }, [repsData]);

    return (
        <div className="flex-grow-1">
            <Row>
                <Col md={4}>
                    <div className="border border-1 rounded bg-white p-3 shadow-sm">
                        <p>
                            One important aspect of managing Advisory Groups is
                            understanding whether the <mark className="fw-bold">Advisory Group is truly
                                representative of the overall population</mark>. An
                            Advisory Group that is not representative could
                            significantly mislead an organization on the
                            importance of any given topic or matter studied. For
                            certain Advisory Groups (EAC's, MAC's, etc.), there
                            is a regulatory requirement that the Advisory Group
                            be representative.
                        </p>
                        <p>
                            Predict Health has built a <strong>multi-faceted scorecard</strong> based
                            on a number of different demographic and
                            environmental variable to determine whether an
                            Advisory Board is compliant with the goal of
                            representativeness. For more information on our
                            scorecard approach, please see the Blog post found
                            here.
                        </p>
                    </div>
                </Col>
                <Col md={8}>
                    <div className="flex-grow-1">
                        {repScoreData === null ? (
                            <Loader />
                        ) : (
                            <DataGrid
                                columns={columns}
                                data={repScoreData}
                                enableFilters={false}
                            />
                        )}
                    </div>
                </Col>
            </Row>

            {detailsModal && (
                <RepresentativenessDetailsModal
                    type={scoreType}
                    show={detailsModal}
                    id={selectedId}
                    onCancel={handleDetailsModalClose}
                />
            )}
        </div>
    );
}

export default withAuth(Representativeness);
