import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Modal } from "react-bootstrap";
import { AppLoadContext } from "../../../../components/ui/AppLoadContext";
import { AlertContext } from "../../../../context/AlertContext";
import SharedForm from "../../../../components/ui/Form";
import CommentItem from "../../../../components/ui/CommentItem";
import {
    AG_MEETING_ATTENDANCE_STATUS_POST,
    AG_MEETING_ATTENDANCE_STATUS_POST_INVERSE,
    AG_MEETING_ATTENDANCE_STATUS_PRE,
    ALERT_TYPE,
    ALIGN,
    POSITIONS, TRANSPORTATION_MODE, VALIDATIONS,
    AG_MEETING_ATTENDANCE_LINK_TYPE
} from '../../../../constants';
import { isObjectEmpty, optionValues, toDate, omitProps } from "../../../../utils/utils";
import {
    addComment,
    addAttendanceLogs,
    getCommentsByMeetingId,
    updateAttendanceLogDetails
} from "../../../../ducks/advisoryGroup";
import messages from "../../../../utils/helper/messages";
import styles from "./EditMemberStatusModal.module.scss";
import Icon from "../../../../components/ui/Icon";


const EditMemberStatusModal = ({ show, isTodayOrPastMeeting, locations, info, data, onCancel, onComment }) => {
    const dispatch = useDispatch();
    const isAppLoaded = useContext(AppLoadContext);
    const { showAlert } = useContext(AlertContext);
    const comments = useSelector(state => state.advisoryGroup.commentList);

    const [isLoading, setIsLoading] = useState(false);
    const [memberDetails, setMemberDetails] = useState(null);
    const [commentBox, setCommentBox] = useState(false);
    const [locationOptions, setLocationOptions] = useState([]);

    const arr = [
        {
            key: 'firstName',
            labelName: 'First Name',
            placeholderName: 'First Name',
            type: 'text',
            disabled: true,
            required: false,
        },
        {
            key: 'lastName',
            labelName: 'Last Name',
            placeholderName: 'Last Name',
            type: 'text',
            disabled: true,
            required: false,
        },
        {
            key: 'recordModified',
            labelName: 'Last Update',
            type: data && data.recordModified ? 'datetime-local' : 'text',
            disabled: true,
            required: false,
        },
        {
            key: 'preMeetingResponse',
            labelName: 'Pre Meeting Response',
            placeholderName: 'Pre Meeting Response',
            type: 'options',
            optionValues: optionValues(AG_MEETING_ATTENDANCE_STATUS_PRE),
            disabled: isTodayOrPastMeeting,
        },
        {
            key: 'meetingParticipation',
            labelName: 'Meeting Participation',
            placeholderName: 'Meeting Participation',
            type: 'options',
            optionValues: optionValues(omitProps(AG_MEETING_ATTENDANCE_STATUS_POST, AG_MEETING_ATTENDANCE_STATUS_POST_INVERSE.FutureMeeting),
                memberDetails && memberDetails.meetingParticipation === 'NA'),
            disabled: !isTodayOrPastMeeting
        },
        {
            key: 'locationId',
            labelName: 'Location',
            placeholderName: 'Location',
            type: 'options',
            optionValues: locationOptions,
            required: false,
            disabled: isTodayOrPastMeeting,
        },
        {
            key: 'transportation',
            labelName: 'Transport',
            placeholderName: 'Transport',
            type: 'options',
            optionValues: optionValues(TRANSPORTATION_MODE, true),
            required: false,
        },
        {
            key: 'accommodationsRequested',
            labelName: 'Accommodations Request',
            placeholderName: 'Accommodations Request',
            type: 'textarea',
            required: false,
            valid: VALIDATIONS.String.noSpecialChars,
        },
        {
            key: 'comment',
            labelName: commentBox ? 'Comments' : '',
            placeholderName: 'Comment',
            type: 'textarea',
            required: false,
            hidden: !commentBox,
            valid: VALIDATIONS.String.noSpecialChars,
        }
    ];

    const handleMemberDetailsChange = (update) => {
        setMemberDetails((details) => ({ ...details, ...update }));
    }

    const handleInputChange = (name, value) => {
        handleMemberDetailsChange({ [name]: value });
    }

    const checkMemberDataEquals = (orgData, subData) => {
        return (orgData.preMeetingResponse === subData.status &&
            orgData.meetingParticipation === subData.statusPost &&
            orgData.locationId === subData.locationId &&
            orgData.transportation === subData.transportation &&
            orgData.accommodationsRequested === subData.accommodationsRequested)
    }

    const handleSuccess = () => {
        setIsLoading(false);
        let successMessage = !info.id ? messages.success.addAttendanceLog : messages.success.updateAttendanceLogData
        showAlert(successMessage, ALERT_TYPE.SUCCESS);
        onCancel();
    }

    const handleError = (error) => {
        setIsLoading(false);
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    }

    const handleSubmit = async (formData) => {
        setIsLoading(true);

        const createAttendanceData = {
            agId: info.agId,
            meetingId: info.meetingId,
            companyId: info.companyId,
            agMemberId: info.agMemberId,
            status: isTodayOrPastMeeting ? data.preMeetingResponse : formData.preMeetingResponse, // as rsvp is disabled for past meeting
            statusPost: !isTodayOrPastMeeting ? data.meetingParticipation : formData.meetingParticipation,
            locationId: formData.locationId === "" ? null : formData.locationId,
            accommodationsRequested: formData.accommodationsRequested,
            careTaker1: null,
            careTaker2: null,
            transportation: formData.transportation === "" ? null : formData.transportation,
        }

        const updateAttendanceData = {
            attendanceLogId: info.id,
            locationId: formData.locationId === "" ? null : formData.locationId,
            status: isTodayOrPastMeeting ? data.preMeetingResponse : formData.preMeetingResponse, // as rsvp is disabled for past meeting
            statusPost: !isTodayOrPastMeeting ? data.meetingParticipation : formData.meetingParticipation,
            accommodationsRequested: formData.accommodationsRequested,
            transportation: formData.transportation === "" ? null : formData.transportation,
        }

        const isMemberDataEqual = checkMemberDataEquals(data, updateAttendanceData);

        if (!info.id) {
            dispatch(
                addAttendanceLogs(
                    [createAttendanceData],
                    handleSuccess,
                    handleError
                )
            );
        } else if (!isMemberDataEqual) {
            dispatch(
                updateAttendanceLogDetails(updateAttendanceData, handleSuccess)
            );
        }

        const commentData = {
            agId: info.agId,
            meetingId: info.meetingId,
            companyId: info.companyId,
            agMemberId: info.agMemberId,
            userId: info.userId,
            comment: formData.comment,
            linkType: AG_MEETING_ATTENDANCE_LINK_TYPE.Member,
        }

        if (formData.comment) {
            dispatch(addComment(commentData, () => {
                setIsLoading(false);
                if (isMemberDataEqual) {
                    showAlert(messages.success.updateAttendanceLogData, ALERT_TYPE.SUCCESS);
                }
                dispatch(getCommentsByMeetingId({
                    meetingId: info.meetingId,
                    agMemberId: info.agMemberId,
                    linkType: AG_MEETING_ATTENDANCE_LINK_TYPE.Member
                }))
                onComment();
            }, handleError));
        }
    }

    const isValidMemberInfo = () => {
        return !isObjectEmpty(memberDetails);
    }

    useEffect(() => {
        if (isAppLoaded && info.id) {
            dispatch(getCommentsByMeetingId({
                meetingId: info.meetingId,
                agMemberId: info.agMemberId,
                linkType: AG_MEETING_ATTENDANCE_LINK_TYPE.Member
            }));
        }
    }, [isAppLoaded, info.id]);

    useEffect(() => {
        if (data !== null) {
            setMemberDetails(data);
        }
    }, [data]);

    useEffect(() => {
        if (locations && locations.length > 0) {
            let locationData = locations.map(location => ({ id: location.id, value: location.name }));
            setLocationOptions([
                { id: '', value: 'Select Location' },
                ...locationData
            ]);
        } else if (locations?.length === 0) {
            setLocationOptions([{ id: '', value: 'No locations available' }])
        }
    }, [locations]);

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onCancel}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>Edit Member Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2">
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={data}
                        loading={isLoading}
                        disabled={!isValidMemberInfo()}
                        onInputChanged={handleInputChange}
                        onSubmit={handleSubmit}
                        onCancel={onCancel}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        submitButtonText="Save"
                    />
                    {!commentBox && <Form.Label>Comments</Form.Label>}
                    <div className={styles.commentBox}>
                        <ul className={`list-unstyled mb-0 ${styles.commentList}`}>
                            {comments?.map(comment => (
                                <CommentItem
                                    key={comment.id}
                                    date={toDate(comment.recordCreated)}
                                    userName={`${comment.firstName} ${comment.lastName}`}
                                >
                                    {comment.comments}
                                </CommentItem>
                            ))}
                            {!comments || comments.length === 0 &&
                                <li className="text-muted px-3 py-2">No comments</li>
                            }
                            <li>
                                <Button
                                    variant="link"
                                    className="text-secondary text-decoration-none d-flex align-items-center"
                                    disabled={commentBox}
                                    onClick={() => setCommentBox(true)}>
                                    <Icon
                                        icon="new"
                                        size={10}
                                        className="me-2"
                                    />
                                    Add Comment
                                </Button>
                            </li>
                        </ul>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default EditMemberStatusModal;
